// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBUfWvR2Xd-VmGRjLz4V07dKMuNmLA7TtM",
  authDomain: "gonsilva-6e9c4.firebaseapp.com",
  projectId: "gonsilva-6e9c4",
  storageBucket: "gonsilva-6e9c4.appspot.com",
  messagingSenderId: "513847970341",
  appId: "1:513847970341:web:d53453bda6137c10e5cdcc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);