import React, { useRef } from 'react';
import './App.css';
import { db } from './firebase';
import { collection, addDoc, Timestamp } from "firebase/firestore";
// 1. Import the extendTheme function
import { extendTheme, ChakraProvider, Button, FormControl, FormLabel, Input, FormHelperText, Flex, HStack, Heading, defineStyle, Box } from '@chakra-ui/react'

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  brand: {
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
}

const headingStyle = defineStyle({
  fontSize: '4xl',
  fontWeight: 'bold',
  fontFamily: 'Lora',
})

const theme = extendTheme({ colors })
function App() {
  const sectionRef = useRef<HTMLDivElement>(null);

  const handleCallToActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  }


  function subscribe(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    const email = (document.getElementById('email') as HTMLInputElement).value.trim();
    if (!email) {
      alert('Please enter a valid email address.');
      return;
    }

    async function addEmailToFirestore() {
      try {
        const docRef = await addDoc(collection(db, "emails"), {
          email: email,
          timestamp: Timestamp.now(),
        });
        console.log("Document written with ID: ", docRef.id);
        alert("Thank you for subscribing!");
      } catch (e) {
        console.error("Error adding document: ", e);
        alert("There was an error processing your request. Please try again later.");
      }
    }

    addEmailToFirestore();
    (document.getElementById('subscription-form') as HTMLFormElement)?.reset();
  }

  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <div className='content-page-container'>
          <section className="content-page content-page-black">
            <Box display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height="100vh">
              <Heading as='h2' variant="headingStyle">Your path starts here!</Heading>
              <br />
              <Button colorScheme="whiteAlpha" variant="solid" size="lg" onClick={handleCallToActionClick}>Learn More</Button>
            </Box>
          </section>
          <section ref={sectionRef} className="content-page content-page-black">
            <Heading as='h2' variant={headingStyle}>Limited offer bootcamp</Heading>
          </section>
          <section className="content-page content-page-white" >
            <Heading as='h2' variant="headingStyle">Subscribe to Our Mailing List</Heading>
            <Flex flexDir="column"
              justifyContent="center"
              alignItems="center"
              color="white">
              <FormControl id="subscription-form">
                <FormLabel htmlFor="email">Email:</FormLabel>
                <HStack>
                  <Input type="email" name="email" id="email" required />
                  <Button colorScheme="blackAlpha" variant="outline" size="lg" type="submit" onClick={subscribe}>Subscribe</Button>
                </HStack>
                <FormHelperText>We'll never share your email.</FormHelperText>
              </FormControl>
            </Flex>
          </section>
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;